import {Group, GroupProps, SpotlightActionData, Text} from '@components/mantine';
import {BadgeVariant, NavigationBadge} from '../NavigationBadge';

export interface NavigationSearchResult extends SpotlightActionData {
    href: string;
    badge?: BadgeVariant;
    keywords: string[];
}

interface NavigationSearchItemProps extends GroupProps {
    action: NavigationSearchResult;
}

export const NavigationSearchItem = ({action, ...others}: NavigationSearchItemProps) => (
    <Group wrap="nowrap" {...others}>
        <Text fw={400} style={{flex: 1}}>
            {action.title}
        </Text>

        {action.badge && <NavigationBadge variant={action.badge} />}
    </Group>
);
