import {notifications} from '@components/mantine';
import {
    CaseAssistConfigListOptions,
    CaseAssistConfigModel,
    PlatformClientFeatures,
    PlatformNoHandlers,
} from '@core/api';
import {ProjectSelectors} from '@core/projects';
import {setKeyValue} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {
    ITableHOCCompositeState,
    IThunkAction,
    PaginationReduxActions,
    TableHOCUtils,
    TableWithPaginationActions,
    turnOffLoading,
} from '@coveord/plasma-react';
import {UserSelectors} from '@core/user';
import {LicenseSelectors} from '@core/license';

import {ServiceState} from '../../ServiceState';
import {locales} from '../../locales/Locales';
import {CaseAssistComponentIds} from '../CaseAssistComponentIds';

export const CaseAssistConfigActionTypes = {
    updateCaseAssistConfigs: 'UPDATE_CASE_ASSIST_CONFIGS',
    fetch: 'FETCH_CASE_ASSIST_CONFIGS',
    delete: 'DELETE_CASE_ASSIST_CONFIG',
    create: 'CREATE_CASE_ASSIST_CONFIG',
};

export interface IUpdateCaseAssistConfigsPayload {
    caseAssistConfigs: CaseAssistConfigModel[];
}

const getCaseAssistConfigByProject = async (state: ServiceState, pageOptions: CaseAssistConfigListOptions) => {
    const access = UserSelectors.getPrivilegesValidator(state);
    const license = LicenseSelectors.getLicense(state);
    const isProjectFeaturesEnabled = access.canViewProjects(license);
    if (isProjectFeaturesEnabled) {
        const project = ProjectSelectors.getSelectedProject(state);
        const caseAssistConfigs = await PlatformNoHandlers.withFeatures(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            PlatformClientFeatures.useProjectFilter(project, 'CASE_ASSIST', '/bulk/get', {}, 'ids'),
        ).caseAssistConfig.list(pageOptions);

        return caseAssistConfigs;
    }

    return await PlatformNoHandlers.caseAssistConfig.list(pageOptions);
};

const fetchCaseAssistConfigs = (): IThunkAction<void, ServiceState> => async (dispatch, getState) => {
    const tableId = CaseAssistComponentIds.CaseAssistConfigsTable;
    dispatch(RequestsActions.request(CaseAssistConfigActionTypes.fetch));
    const compositeState: ITableHOCCompositeState = TableHOCUtils.getCompositeState(tableId, getState());
    const pageOptions: CaseAssistConfigListOptions = {
        page: compositeState.pageNb,
        perPage: compositeState.perPage,
    };
    try {
        const caseAssistConfigs = await getCaseAssistConfigByProject(getState(), pageOptions);

        if (isPagingOutOfRange(pageOptions, caseAssistConfigs.totalPages)) {
            const lastAvailablePage = Math.max(caseAssistConfigs.totalPages - 1, 0);
            dispatch(PaginationReduxActions.changePage(TableHOCUtils.getPaginationId(tableId), lastAvailablePage));
        } else {
            dispatch(
                setKeyValue(CaseAssistConfigActionTypes.updateCaseAssistConfigs, caseAssistConfigs.configurations),
            );
            dispatch(TableWithPaginationActions.setCount(tableId, caseAssistConfigs.totalEntries));
            dispatch(turnOffLoading([tableId]));
        }
        dispatch(RequestsActions.success(CaseAssistConfigActionTypes.fetch));
    } catch (exception) {
        dispatch(RequestsActions.failure(CaseAssistConfigActionTypes.fetch, exception));
        notifications.showError(locales.format('fetchCaseAssistConfigsFailed'));
    }
};

const isPagingOutOfRange = (pageOptions: CaseAssistConfigListOptions, totalPages: number) =>
    totalPages > 0 && (pageOptions.page ?? 0) >= totalPages;

const deleteCaseAssistConfig =
    (caseAssistConfigId: string): IThunkAction<void, ServiceState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(CaseAssistConfigActionTypes.delete));
        try {
            await PlatformNoHandlers.caseAssistConfig.delete(caseAssistConfigId);
            dispatch(RequestsActions.success(CaseAssistConfigActionTypes.delete));
            dispatch(CaseAssistConfigActions.fetchCaseAssistConfigs());
        } catch (exception) {
            dispatch(RequestsActions.failure(CaseAssistConfigActionTypes.delete, exception));
            notifications.showError(locales.format('deleteCaseAssistConfigFailed'));
        }
    };

export const CaseAssistConfigActions = {
    fetchCaseAssistConfigs,
    deleteCaseAssistConfig,
};
