import {factory, Factory, SegmentedControl} from '@components/mantine';
import {Region} from '@core/api';
import {Config} from '@core/configuration';
import {useOrganizationPicker} from './OrganizationPickerContext';

export type OrganizationPickerRegionsFactory = Factory<{
    props: NonNullable<unknown>;
    ref: HTMLDivElement;
    stylesNames: string;
    compound: true;
}>;

interface RegionOption {
    value: Region;
    label: string;
}

type Env = 'production' | 'stg' | 'dev' | 'hipaa';

const productionRegions: RegionOption[] = [
    {value: Region.US, label: 'US'},
    {value: Region.EU, label: 'EU'},
    {value: Region.AU, label: 'AU'},
    {value: Region.CA, label: 'CA'},
];
const stgRegions: RegionOption[] = [{value: Region.US, label: 'US'}];

const devRegions: RegionOption[] = [
    {value: Region.US, label: 'US'},
    {value: Region.EU, label: 'EU'},
];
const hipaaRegions: RegionOption[] = [{value: Region.US, label: 'US'}];

const regionsByEnv: Record<Env, RegionOption[]> = {
    dev: devRegions,
    stg: stgRegions,
    production: productionRegions,
    hipaa: hipaaRegions,
};

export const OrganizationPickerRegions = factory<OrganizationPickerRegionsFactory>((_props, ref) => {
    const {region, setRegion, getStyles} = useOrganizationPicker();
    const regions = regionsByEnv[Config.env as Env];

    if (!regions || regions?.length < 2) {
        return null;
    }

    const onChange = (newRegion: string) => {
        setRegion(newRegion as Region);
    };

    return <SegmentedControl ref={ref} fullWidth value={region} onChange={onChange} data={regions} />;
});
