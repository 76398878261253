import {Prompt, Text, useDisclosure} from '@components/mantine';
import {Translation} from '@core/locales';
import {OrganizationSelectors} from '@core/organization';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ms from 'ms';
import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Locales} from '../../strings/Locales';
import {getThrottle} from '../../utils';
import {getFormatedTimeUntilLogout} from '../../utils/GetFormatedTimeUntilLogout';

dayjs.extend(duration);

const OPEN_PROMPT_DELAY = ms('25m');
const PROMPT_LOGOUT_DELAY = ms('5m');
// cannot use React.useState for this one since many write are happening too fast
let promptTimerId = -1;

const getIdleLogoutDelay = (adminUITimeoutInSeconds) =>
    adminUITimeoutInSeconds ? adminUITimeoutInSeconds * 1000 : OPEN_PROMPT_DELAY;

const useBroadcastData = ({id, onChange}) => {
    const generatedId = useMemo(() => performance.now(), []);
    const broadcastUpdate = (value: string) => {
        localStorage.setItem(id, JSON.stringify({value, sourceId: generatedId}));
    };

    const onLocalStorageUpdate = (ev: StorageEvent) => {
        if (ev.key === id && ev.newValue) {
            const parsed = JSON.parse(ev.newValue);
            if (parsed?.sourceId !== generatedId) {
                onChange();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('storage', onLocalStorageUpdate);
        return () => {
            window.removeEventListener('storage', onLocalStorageUpdate);
        };
    }, []);
    return [broadcastUpdate];
};

interface IdleLogoutModalProps {
    onIdle: () => void;
}

const ActualIdleLogoutModel: FunctionComponent<IdleLogoutModalProps> = ({onIdle}) => {
    const [opened, {open, close}] = useDisclosure(false);
    const [logoutIntervalId, setLogoutIntervalId] = useState(-1);
    const [logoutAt, setLogoutAt] = useState<number | null>(null);
    const {adminUITimeoutInSeconds} = useSelector(OrganizationSelectors.getOrganization);

    const openPromptDelay = getIdleLogoutDelay(adminUITimeoutInSeconds);

    const checkTimer = () => {
        if (logoutAt !== null && Date.now() >= logoutAt) {
            onIdle();
        } else {
            const id = window.setTimeout(checkTimer, 1000);
            setLogoutIntervalId(id);
        }
    };

    const stay = () => {
        close();
        onUserActivity();
        clearTimeout(logoutIntervalId);
        setLogoutAt(null);
    };

    const openModalIfNeeded = () => {
        if (!opened) {
            open();
            setLogoutAt(Date.now() + PROMPT_LOGOUT_DELAY);
        }
    };

    const resetTimer = () => {
        clearTimeout(promptTimerId);
        promptTimerId = window.setTimeout(openModalIfNeeded, openPromptDelay);
    };

    const [update] = useBroadcastData({
        id: 'last-activity',
        onChange: () => {
            if (!document.hasFocus()) {
                stay();
            }
        },
    });

    const onUserActivity = useMemo<any>(
        () =>
            getThrottle(() => {
                resetTimer();
                if (document.hasFocus()) {
                    update(dayjs().toJSON());
                }
            }, 1000),
        [],
    );

    useEffect(() => {
        window.onmousemove = onUserActivity;
        window.onmousedown = onUserActivity; // catches touchscreen presses as well
        window.ontouchstart = onUserActivity; // catches touchscreen swipes as well
        window.onclick = onUserActivity; // catches touchpad clicks as well
        window.onkeydown = onUserActivity;
        window.addEventListener('scroll', onUserActivity, true);

        resetTimer();
    }, []);

    useEffect(() => {
        if (logoutAt !== null) {
            checkTimer();
        }
    }, [logoutAt, setLogoutIntervalId]);

    const now = dayjs();
    const timeUntilAutomaticLogout = dayjs.duration(dayjs(logoutAt).diff(now));

    return (
        <Prompt
            id={'idle-logout-modal'}
            variant="warning"
            opened={opened}
            title={Locales.format('IdleLogoutPromptTitle')}
            onClose={() => stay()}
        >
            <Translation
                t={Locales}
                i18nKey="IdleLogoutPromptDescription"
                options={{time: getFormatedTimeUntilLogout(timeUntilAutomaticLogout)}}
            >
                <Text inherit span fw={500} />
            </Translation>
            <Prompt.Footer>
                <Prompt.CancelButton onClick={onIdle}>{Locales.format('IdleLogoutPromptLogout')}</Prompt.CancelButton>
                <Prompt.ConfirmButton onClick={stay}>{Locales.format('IdleLogoutPromptStay')}</Prompt.ConfirmButton>
            </Prompt.Footer>
        </Prompt>
    );
};

export const IdleLogoutModal: FunctionComponent<IdleLogoutModalProps> = ({onIdle}) => {
    const {adminUITimeoutInSeconds} = useSelector(OrganizationSelectors.getOrganization);

    if (adminUITimeoutInSeconds === undefined) {
        return null;
    } else {
        return <ActualIdleLogoutModel onIdle={onIdle} />;
    }
};
