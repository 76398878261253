import {
    ListOperationalStatusSourcesParams,
    Platform,
    PlatformClientFeatures,
    SourceModel,
    SourceOperationalStatus,
} from '@core/api';
import {ProjectSelectors} from '@core/projects';
import {setKeyValue} from '@core/store';
import {UserSelectors} from '@core/user';
import {LicenseSelectors} from '@core/license';
import {PerPageNumbers, RequestsActions} from '@coveord/jsadmin-common';
import {ITableHOCCompositeState, IThunkAction, TableHOCUtils, TableWithPaginationActions} from '@coveord/plasma-react';

import {AdminState} from '../../application/Reducers';
import {SourcesConstants} from '../SourcesConstants';

export const SourcesActionTypes = {
    set: 'SET_SOURCES',
    fetch: 'FETCH_SOURCES',
    fetchAll: 'FETCH_ALL_SOURCES',
};

const fetchAll = (): IThunkAction<Promise<SourceModel[]>> => (dispatch) => {
    const makeRequest = async () => {
        const {totalPages, sourceModels} = await Platform.source.list();
        let totalSourceModel: SourceModel[] = sourceModels;

        for (let i = 1; i < totalPages; i++) {
            const nextSourceModels: SourceModel[] = (await Platform.source.list({page: i})).sourceModels;
            totalSourceModel = totalSourceModel.concat(nextSourceModels);
        }

        return totalSourceModel;
    };
    return dispatch(RequestsActions.handle(SourcesConstants.Requests.fetchAll, makeRequest));
};

const fetchAllWithPageAndFilter =
    (page: number, filter: string, perPage = 100): IThunkAction<ReturnType<typeof Platform.source.list>, AdminState> =>
    (dispatch) => {
        const params = {
            page,
            filter,
            perPage,
        };

        return dispatch(RequestsActions.handle(SourcesConstants.Requests.fetchAll, () => Platform.source.list(params)));
    };

const fetch =
    (id: string, isLoading: boolean): IThunkAction<Promise<void | SourceModel[]>, AdminState> =>
    (dispatch, getState) => {
        const state = getState();
        const access = UserSelectors.getPrivilegesValidator(state);
        const license = LicenseSelectors.getLicense(state);
        const isProjectFeaturesEnabled = access.canViewProjects(license);
        const compositeState: ITableHOCCompositeState = TableHOCUtils.getCompositeState(id, state);
        const predicates: Record<string, string> = compositeState.predicates.reduce(
            (acc, {id: predicateId, value}) => ({
                ...acc,
                [predicateId]: value,
            }),
            {},
        );
        const params: ListOperationalStatusSourcesParams = {
            sourceOperationalStatus: SourceOperationalStatus.ALL,
            ...predicates,
            filter: compositeState.filter || undefined,
            page: compositeState.pageNb || 0,
            perPage: compositeState.perPage || PerPageNumbers[1],
        };

        const makeRequest = async () => {
            try {
                let sourceModels = [];
                let totalEntries = 0;

                if (isProjectFeaturesEnabled) {
                    const project = ProjectSelectors.getSelectedProject(state);
                    ({sourceModels, totalEntries} = await Platform.withFeatures(
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        PlatformClientFeatures.useProjectFilter(project, 'SOURCE', '/ids'),
                    ).source.listOperationalStatus(params));
                } else {
                    ({sourceModels, totalEntries} = await Platform.source.listOperationalStatus(params));
                }

                dispatch(TableWithPaginationActions.setCount(id, totalEntries));
                dispatch(setKeyValue(SourcesConstants.Requests.fetch, sourceModels));

                return sourceModels;
            } catch (exception) {
                dispatch(RequestsActions.failure(SourcesConstants.Requests.fetch, exception));
            }
        };

        return dispatch(RequestsActions.handle(SourcesActionTypes.fetch, makeRequest, isLoading));
    };

export const SourcesActions = {
    fetch,
    fetchAll,
    fetchAllWithPageAndFilter,
};
