export const JiraServiceManagementIcon = () => (
    <svg
        fill="none"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        focusable="false"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="#1868DB"
            d="M18.79 13.257h7.325c1.1 0 1.476 1.046.805 1.878L15.464 29.274c-3.702-2.951-3.353-7.62-.644-11.027zm-5.66 5.634H5.806c-1.1 0-1.476-1.046-.805-1.878L16.457 2.874c3.702 2.951 3.3 7.566.617 11z"
        ></path>
    </svg>
);
