import {TeamProject} from '../data';

type ReturnUseSlackChannel = [boolean, string, string];

export const useSlackChannel = (team?: TeamProject): ReturnUseSlackChannel => {
    const hasSupportChannel = Boolean(team?.contact?.slack);
    if (!team || !hasSupportChannel) {
        return [false, '', ''] as const;
    }

    const channelSlug = team.contact.slack!.replace(/^#/, '');
    const channelWithHash = `#${channelSlug}`;
    const channelLink = `https://coveo.slack.com/app_redirect?channel=${channelSlug}`;

    return [hasSupportChannel, channelWithHash, channelLink] as const;
};
