// This file is generated by the update-rd-teams script. Run "pnpm update:rd-teams" to update.
// It needs to be pushed to the repository. Otherwise, all builds could break if a team changes.

import {type Team, type TeamProject} from './Teams.types';

export const teams: Record<Team, TeamProject | null> = {
    'backend-foundation': {
        slug: 'backend-foundation',
        displayName: 'Backend Foundation',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'FOUND',
                id: '12600',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-platform',
        },
    },
    cicd: {
        slug: 'cicd',
        displayName: 'CI/CD',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'DT',
                id: '14907',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-dev_tooling',
        },
    },
    'cloud-advisor': {
        slug: 'cloud-advisor',
        displayName: 'Cloud Advisor',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'CA',
                id: '15115',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-cloud-advisers',
        },
    },
    'cloud-infra': {
        slug: 'cloud-infra',
        displayName: 'Cloud Infra',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'CLOUDINFRA',
                id: '13800',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-infra',
        },
    },
    'cloud-intelligence': {
        slug: 'cloud-intelligence',
        displayName: 'Cloud Intelligence',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'SRE',
                id: '15290',
                bugIssueTypeId: '1',
            },
            {
                key: 'FINOPS',
                id: '15531',
                bugIssueTypeId: '1',
            },
            {
                key: 'CIA',
                id: '15292',
                bugIssueTypeId: '1',
            },
        ],
        contact: {},
    },
    'commerce-administrators': {
        slug: 'commerce-administrators',
        displayName: 'Commerce Administrators',
        units: ['commerce'],
        jiraProjects: [
            {
                key: 'MHUB',
                id: '15258',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#commerce-rd-administrators',
        },
    },
    'commerce-implement': {
        slug: 'commerce-implement',
        displayName: 'Commerce Implement',
        units: ['commerce'],
        jiraProjects: [
            {
                key: 'LENS',
                id: '15194',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-commerce',
        },
    },
    'commerce-routemasters': {
        slug: 'commerce-routemasters',
        displayName: 'Commerce Routemasters',
        units: ['commerce'],
        jiraProjects: [
            {
                key: 'CAPI',
                id: '15241',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-commerce',
        },
    },
    'connectivity-core': {
        slug: 'connectivity-core',
        displayName: 'Connectivity Core',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'CTCORE',
                id: '10907',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#team_connectors',
        },
    },
    'coveo-labs-technical-powerhouse': {
        slug: 'coveo-labs-technical-powerhouse',
        displayName: 'Coveo Labs Technical Powerhouse',
        units: ['advisors-and-specialists'],
        jiraProjects: [
            {
                key: 'LAB',
                id: '14902',
                bugIssueTypeId: '1',
            },
            {
                key: 'UNI',
                id: '15192',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#coveo-labs',
        },
    },
    ctinfra: {
        slug: 'ctinfra',
        displayName: 'Connectors Infrastructure',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'CTINFRA',
                id: '14925',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#team_ct_infra',
        },
    },
    'data-analytics-engineering': {
        slug: 'data-analytics-engineering',
        displayName: 'Data Analytics Engineering',
        units: ['data-platform'],
        jiraProjects: [],
        contact: {
            slack: '#support-dataplatform',
        },
    },
    'database-data-management': {
        slug: 'database-data-management',
        displayName: 'Database & Data Management',
        units: ['data-platform'],
        jiraProjects: [
            {
                key: 'DDM',
                id: '15259',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-dataplatform',
        },
    },
    dataplatformfoundation: {
        slug: 'dataplatformfoundation',
        displayName: 'Data Platform Foundation',
        units: ['data-platform'],
        jiraProjects: [
            {
                key: 'UA',
                id: '10904',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-dataplatform',
        },
    },
    'dev-tooling': {
        slug: 'dev-tooling',
        displayName: 'Dev Tooling',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'DT',
                id: '14907',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-dev_tooling',
        },
    },
    documentation: {
        slug: 'documentation',
        displayName: 'Documentation',
        units: ['implementation-enablement'],
        jiraProjects: [
            {
                key: 'DOC',
                id: '11300',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#public-documentation',
        },
    },
    dx: {
        slug: 'dx',
        displayName: 'DX',
        units: ['implementation-enablement'],
        jiraProjects: [
            {
                key: 'CDX',
                id: '15096',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#searchuialertsprd',
        },
    },
    'frontend-foundation': {
        slug: 'frontend-foundation',
        displayName: 'Frontend Foundation',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'ADUI',
                id: '15157',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-admin-ui',
            serviceDesk: 'https://coveord.atlassian.net/servicedesk/customer/portal/83/group/277',
        },
    },
    'index-core-search': {
        slug: 'index-core-search',
        displayName: 'Index Core Search',
        units: ['query-execution'],
        jiraProjects: [
            {
                key: 'IDX',
                id: '10903',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-index',
        },
    },
    'index-infrastructure': {
        slug: 'index-infrastructure',
        displayName: 'Index Infrastructure',
        units: ['query-execution'],
        jiraProjects: [
            {
                key: 'IDXINFRA',
                id: '15031',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-index-infra',
        },
    },
    index: {
        slug: 'index',
        displayName: 'Index Core',
        units: ['query-execution'],
        jiraProjects: [
            {
                key: 'IDX',
                id: '10903',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-index',
        },
    },
    'indexing-pipeline': {
        slug: 'indexing-pipeline',
        displayName: 'Indexing Pipeline',
        units: ['query-execution'],
        jiraProjects: [
            {
                key: 'IP',
                id: '14959',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-indexing-pipeline',
        },
    },
    infrastructure: {
        slug: 'infrastructure',
        displayName: 'Infrastructure',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'CLOUDINFRA',
                id: '13800',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-infra',
        },
    },
    'ml-case-assist': {
        slug: 'ml-case-assist',
        displayName: 'ML Case Assist',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'NLP',
                id: '15182',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-commerce-ai': {
        slug: 'ml-commerce-ai',
        displayName: 'ML Commerce AI',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'COMAI',
                id: '15248',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-core-ai-dev': {
        slug: 'ml-core-ai-dev',
        displayName: 'ML Core AI Dev',
        units: ['ml-platform'],
        jiraProjects: [
            {
                key: 'COREAI',
                id: '15285',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-core-nlp': {
        slug: 'ml-core-nlp',
        displayName: 'ML CoreNLP',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'CORENLP',
                id: '15561',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#core-nlp',
        },
    },
    'ml-operations': {
        slug: 'ml-operations',
        displayName: 'ML Operations',
        units: ['ml-platform'],
        jiraProjects: [
            {
                key: 'ML',
                id: '15168',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-payg': {
        slug: 'ml-payg',
        displayName: 'ML Personalisation As You Go',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'COMAI',
                id: '15248',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-platform-building': {
        slug: 'ml-platform-building',
        displayName: 'ML Platform Building',
        units: ['ml-platform'],
        jiraProjects: [
            {
                key: 'MLX',
                id: '15193',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-platform-serving': {
        slug: 'ml-platform-serving',
        displayName: 'ML Platform Serving',
        units: ['ml-platform'],
        jiraProjects: [
            {
                key: 'REVEAL',
                id: '12900',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-profit': {
        slug: 'ml-profit',
        displayName: 'ML Profitability',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'COMAI',
                id: '15248',
                bugIssueTypeId: '1',
            },
            {
                key: 'IVADO',
                id: '15229',
                bugIssueTypeId: '13743',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-service-ai': {
        slug: 'ml-service-ai',
        displayName: 'ML Service AI',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'NLP',
                id: '15182',
                bugIssueTypeId: '1',
            },
            {
                key: 'SVCAI',
                id: '15282',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    'ml-smartsnippets': {
        slug: 'ml-smartsnippets',
        displayName: 'ML SmartSnippets',
        units: ['applied-data-science'],
        jiraProjects: [
            {
                key: 'NLP',
                id: '15182',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-machine-learning',
        },
    },
    offsec: {
        slug: 'offsec',
        displayName: 'Offensive Security',
        units: ['corpo-security'],
        jiraProjects: [
            {
                key: 'CSEC',
                id: '11200',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-security',
        },
    },
    organization: {
        slug: 'organization',
        displayName: 'Organization',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'ORG',
                id: '15243',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-platform',
        },
    },
    'product-intelligence': {
        slug: 'product-intelligence',
        displayName: 'Product Intelligence',
        units: ['advisors-and-specialists'],
        jiraProjects: [
            {
                key: 'DASH',
                id: '15162',
                bugIssueTypeId: '13482',
            },
        ],
        contact: {
            slack: '#product-intelligence',
        },
    },
    'real-time-platform': {
        slug: 'real-time-platform',
        displayName: 'Real Time Platform',
        units: ['data-platform'],
        jiraProjects: [
            {
                key: 'CCC',
                id: '15211',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-dataplatform',
        },
    },
    'salesforce-integration': {
        slug: 'salesforce-integration',
        displayName: 'Salesforce Integration',
        units: ['service'],
        jiraProjects: [
            {
                key: 'SFINT',
                id: '12000',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-service-rd',
        },
    },
    'search-ui': {
        slug: 'search-ui',
        displayName: 'Search UI',
        units: ['implementation-enablement'],
        jiraProjects: [
            {
                key: 'KIT',
                id: '15057',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#searchuialertsprd',
        },
    },
    searchapi: {
        slug: 'searchapi',
        displayName: 'Search API',
        units: ['query-execution'],
        jiraProjects: [
            {
                key: 'SEARCHAPI',
                id: '11002',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-searchapi',
        },
    },
    'security-cache': {
        slug: 'security-cache',
        displayName: 'Security Cache',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'SECCACHE',
                id: '11007',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-security-cache',
        },
    },
    security: {
        slug: 'security',
        displayName: 'Security',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'DEF',
                id: '15212',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-security',
        },
    },
    'service-connectivity': {
        slug: 'service-connectivity',
        displayName: 'Service Connectivity',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'CTSVC',
                id: '11004',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#salesforce-connectivity-alerts',
        },
    },
    'service-core': {
        slug: 'service-core',
        displayName: 'Service Core',
        units: ['service'],
        jiraProjects: [
            {
                key: 'SVCC',
                id: '15175',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-service-rd',
        },
    },
    'service-data': {
        slug: 'service-data',
        displayName: 'Service Data',
        units: ['service'],
        jiraProjects: [
            {
                key: 'SVCDATA',
                id: '15268',
                bugIssueTypeId: '1',
            },
        ],
        contact: {},
    },
    'service-integrations': {
        slug: 'service-integrations',
        displayName: 'Service Integrations',
        units: ['service'],
        jiraProjects: [
            {
                key: 'SVCC',
                id: '15175',
                bugIssueTypeId: '1',
            },
        ],
        contact: {},
    },
    sources: {
        slug: 'sources',
        displayName: 'Sources',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'SRC',
                id: '10902',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#sources_team',
        },
    },
    'transformation-and-enablement': {
        slug: 'transformation-and-enablement',
        displayName: 'Transformation & Enablement',
        units: ['platform-engineering'],
        jiraProjects: [
            {
                key: 'CA',
                id: '15115',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#peng-transformation',
        },
    },
    websites: {
        slug: 'websites',
        displayName: 'Websites Connectivity',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'WEB',
                id: '10906',
                bugIssueTypeId: '1',
            },
        ],
        contact: {
            slack: '#support-websites',
        },
    },
    wpc: {
        slug: 'wpc',
        displayName: 'Workplace Connectivity',
        units: ['content-ingestion'],
        jiraProjects: [
            {
                key: 'WPC',
                id: '15244',
                bugIssueTypeId: '1',
            },
        ],
        contact: {},
    },
};
