import {
    Box,
    BoxProps,
    Factory,
    Group,
    Kbd,
    Menu,
    StylesApiProps,
    Text,
    factory,
    useHotkeys,
    useProps,
    useStyles,
    useWindowEvent,
} from '@components/mantine';
import {Guard} from '@components/security';
import {Config} from '@core/configuration';
import {Team, TeamProject, teams, useJiraLink, useOwnership, useSlackChannel} from '@core/debug';
import {Translation} from '@core/locales';
import {UserSelectors} from '@core/user';
import {BugReportSize24Px, Icon} from '@coveord/plasma-react-icons';
import {os} from 'platform';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';

import {Locales} from '../../strings/Locales';
import classes from './BugReporter.module.css';
import {JiraIcon} from './JiraIcon';
import {JiraServiceManagementIcon} from './JiraServiceManagementIcon';
import {SlackIcon} from './SlackIcon';
type BugReporterStylesNames = 'root' | 'teamName';

interface BugReporterProps extends BoxProps, StylesApiProps<BugReporterFactory> {
    icon?: Icon;
    defaultOwner?: Team;
    size?: number;
}

export type BugReporterFactory = Factory<{
    props: BugReporterProps;
    ref: HTMLDivElement;
    stylesNames: BugReporterStylesNames;
}>;

const defaultProps: Partial<BugReporterProps> = {
    icon: BugReportSize24Px,
    defaultOwner: 'frontend-foundation',
    size: 72,
};

const useBoundingRect = <T extends HTMLElement = HTMLDivElement>() => {
    const ref = useRef<T>(null);
    const [rect, setRect] = useState<Omit<DOMRectReadOnly, 'toJSON'> | null>({
        x: 0,
        y: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 0,
        height: 0,
    });

    const computePosition = useCallback(() => {
        if (ref.current) {
            setRect(ref.current.getBoundingClientRect());
        }
    }, []);

    useEffect(computePosition, [ref.current]);
    useWindowEvent('resize', computePosition);

    return [ref, rect] as const;
};

const BugReporterInner = factory<BugReporterFactory>((_props, ref) => {
    const {
        classNames,
        styles,
        style,
        className,
        vars,
        icon: IconComponent,
        defaultOwner,
        size,
        ...others
    } = useProps('BugReporter', defaultProps, _props);
    const getStyles = useStyles<BugReporterFactory>({
        name: 'BugReporter',
        classes,
        vars,
        classNames,
        className,
        style,
        props: _props,
        styles,
    });
    const {owner} = useOwnership();
    const ownerProject = teams[owner ?? defaultOwner];

    const jiraTicketUrl = useJiraLink(Config.app, ownerProject);
    const createTicketUrl = ownerProject.contact.serviceDesk ?? jiraTicketUrl;
    useHotkeys([[`mod+b`, () => void window.open(createTicketUrl, '_blank')]]);
    const [opened, setOpened] = useState(false);
    const [placeholderRef, position] = useBoundingRect();

    return (
        <Menu opened={opened} onChange={setOpened} trigger="click-hover">
            <Menu.Target>
                <Box ref={placeholderRef} w={size} h={size}>
                    {createPortal(
                        // eslint-disable-next-line @helpers/no-style-or-styles-prop
                        <Box
                            ref={ref}
                            role="menu"
                            aria-label="bug reporter"
                            {...getStyles('root', {className: opened ? 'open' : undefined})}
                            style={{
                                top: position.top,
                                bottom: position.bottom,
                                left: position.left,
                                right: position.right,
                                height: size,
                                width: size,
                            }}
                            {...others}
                        >
                            <IconComponent width={24} />
                        </Box>,
                        document.body,
                    )}
                </Box>
            </Menu.Target>
            <Menu.Dropdown>
                <Box p="xs">
                    <Translation t={Locales} i18nKey="BugReporter.header">
                        <Text />
                    </Translation>
                </Box>
                <Menu.Divider />
                <Box p="xs">
                    <Translation
                        t={Locales}
                        i18nKey="BugReporter.owners"
                        options={{teamName: ownerProject.displayName}}
                    >
                        <Text span {...getStyles('teamName')} />
                    </Translation>
                </Box>
                <CreateTicketOption link={createTicketUrl.toString()} project={ownerProject} />
                {!ownerProject.contact.serviceDesk && <ContactOnSlackOption project={ownerProject} />}
            </Menu.Dropdown>
        </Menu>
    );
});

const CreateTicketOption: FunctionComponent<{
    link: string;
    project: TeamProject;
}> = ({project, link}) => {
    const isUsingAppleProduct = os.family === 'OS X' || os.family === 'iOS';
    const isServiceDesk = !!project.contact.serviceDesk;
    return (
        <Menu.Item
            component="a"
            target="_blank"
            href={link}
            rel="noopener noreferrer"
            leftSection={isServiceDesk ? <JiraServiceManagementIcon /> : <JiraIcon />}
            rightSection={
                <Group wrap="nowrap" gap={4}>
                    <Kbd size="xs">{isUsingAppleProduct ? '⌘' : 'Ctrl'}</Kbd>+<Kbd size="xs">B</Kbd>
                </Group>
            }
        >
            {isServiceDesk
                ? Locales.format('BugReporter.createATicket')
                : Locales.format('BugReporter.project', {projectKey: project.jiraProjects[0].key})}
        </Menu.Item>
    );
};

const ContactOnSlackOption: FunctionComponent<{
    project: TeamProject;
}> = ({project}) => {
    const [hasSupportChannel, supportChannelName, supportChannelLink] = useSlackChannel(project);
    return (
        hasSupportChannel && (
            <Menu.Item component="a" target="_blank" href={supportChannelLink} leftSection={<SlackIcon />}>
                <Text span td="underline">
                    {supportChannelName}
                </Text>
            </Menu.Item>
        )
    );
};

export const BugReporter = factory<BugReporterFactory>((props, ref) => {
    const additionalInfo = useSelector(UserSelectors.getAdditionalInformation);
    return (
        <Guard canRender={({user}) => user.isInternalUser && additionalInfo.showFeedbackButton !== 'false'}>
            <BugReporterInner ref={ref} {...props} />
        </Guard>
    );
});
